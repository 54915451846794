import { useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TicketType } from "../../../../types/ticket_types";
import { StyledInput } from "../../../../components/forms/StyledInput";
import { DollarsInput } from "../../../../components/forms/DollarsInput";
import { StyledTextarea } from "../../../../components/forms/StyledTextarea";
import {
  createTicketType,
  updateTicketType,
} from "../../../../services/api/ticketTypes";
import { Panel } from "../../../../components/Panel";
import { useSubEvents } from "../../hooks/useSubEvents";

interface TicketTypeFormProps {
  eventId: string;
  ticketType?: TicketTypeFormValues | null;
  onSubmit?: (data: TicketType) => void;
}

type TicketTypeFormValues = TicketType & {
  auto_enroll_event_ids?: string[];
};

export const TicketTypeForm: React.FC<TicketTypeFormProps> = ({
  eventId,
  ticketType,
  onSubmit: onSubmitProp,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm<TicketTypeFormValues>({
    values:
      ticketType ??
      ({
        id: "",
        name: "",
        price_in_cents: 0,
      } as TicketTypeFormValues),
    mode: "onBlur",
  });

  const { subEvents } = useSubEvents(eventId);

  const onSubmit = () => {
    handleSubmit(async (data: TicketType) => {
      let ticketType;
      if (data.id) {
        ticketType = await updateTicketType(data);
      } else {
        ticketType = await createTicketType(data);
      }

      if (onSubmitProp) {
        onSubmitProp(ticketType);
      }
    })();
  };

  return (
    <form>
      <Panel>
        <StyledInput
          type="hidden"
          name="event_id"
          register={register}
          inputProps={{ value: eventId }}
        />

        <VStack gap={4}>
          <StyledInput
            name="name"
            register={register}
            label="Name"
            options={{
              required: {
                value: true,
                message: "Enter a name for this ticket",
              },
            }}
            inputProps={{ placeholder: "General Admission" }}
            error={errors.name}
          />
          <StyledInput
            name="capacity"
            type="number"
            register={register}
            label="Capacity"
          />
          <DollarsInput name="price_in_cents" label="Price" control={control} />

          <StyledTextarea
            name="description"
            register={register}
            label="Ticket details"
            error={errors.description}
            options={{
              maxLength: {
                value: 255,
                message: "Description must be less than 255 characters",
              },
            }}
          />
        </VStack>
      </Panel>

      {subEvents.length > 0 && (
        <Panel>
          <Heading as="h2" size="sm" marginBottom={2}>
            Ticket signs up user for
          </Heading>

          <CheckboxGroup defaultValue={ticketType?.auto_enroll_event_ids}>
            <VStack width="100%">
              {subEvents.map((event) => (
                <HStack key={event.id} width="100%">
                  <Checkbox
                    value={event.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue("auto_enroll_event_ids", [
                          ...(getValues("auto_enroll_event_ids") || []),
                          event.id,
                        ]);
                      } else {
                        setValue(
                          "auto_enroll_event_ids",
                          (getValues("auto_enroll_event_ids") || []).filter(
                            (id) => id !== event.id
                          )
                        );
                      }
                    }}
                  />
                  <Text size="sm">{event.name}</Text>
                  <Spacer />
                </HStack>
              ))}
            </VStack>
          </CheckboxGroup>
        </Panel>
      )}

      <Button
        variant="primary"
        width="100%"
        marginTop={6}
        marginBottom={12}
        onClick={onSubmit}
      >
        Save
      </Button>
    </form>
  );
};
