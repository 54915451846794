import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEventTickets } from "../../../hooks/useEventTickets";
import { VianikoEvent } from "../../../types/events";
import { TiTicket } from "react-icons/ti";
import { TicketTypesSection } from "./forms/TicketTypesSection";
import { ButtonLink } from "../../../components/ButtonLink";
import {
  ticketTypeEditUrl,
  ticketTypeNewUrl,
} from "../../../services/routes/urlBuilder";
import { Panel } from "../../../components/Panel";
import { useNavigate } from "react-router-dom";
import { TicketType } from "../../../types/ticket_types";
import { eventLevelTicketsUnvailable } from "../services";

interface EventTicketsProps {
  event: VianikoEvent;
  isAdmin: boolean;
  onBuyTickets: () => void;
}

export const EventTickets: React.FC<EventTicketsProps> = ({
  event,
  isAdmin,
  onBuyTickets,
}) => {
  const navigate = useNavigate();
  const { tickets } = useEventTickets(event.id);

  const handleEditTicketType = (ticketType: TicketType) => {
    navigate(ticketTypeEditUrl(event.id, ticketType.id));
  };

  return (
    <Panel>
      <VStack gap={4}>
        {tickets.length > 0 && (
          <Box width="100%">
            <Heading size="md" marginBottom={2}>
              Your tickets
            </Heading>
            <VStack width="100%">
              {tickets.map((ticket) => (
                <HStack key={ticket.id} width="100%">
                  <Icon as={TiTicket} />
                  <Text size="md">{ticket.name}</Text>
                </HStack>
              ))}
            </VStack>

            {!eventLevelTicketsUnvailable(event) && (
              <Button
                variant="primary"
                size="lg"
                width="100%"
                marginTop={4}
                onClick={onBuyTickets}
              >
                Buy more tickets
              </Button>
            )}
          </Box>
        )}

        {isAdmin && (
          <Box width="100%">
            <Heading size="md" width="100%">
              Event tickets
            </Heading>

            <TicketTypesSection
              ticketTypes={event.ticket_types}
              currencyIsoCode={event.currency_iso_code}
              onEdit={handleEditTicketType}
              isAdmin={isAdmin}
            />

            <ButtonLink
              to={ticketTypeNewUrl(event.id)}
              buttonProps={{
                variant: "primary",
                size: "lg",
                width: "100%",
              }}
              width="100%"
              marginTop={4}
            >
              Add a ticket
            </ButtonLink>
          </Box>
        )}
      </VStack>
    </Panel>
  );
};
