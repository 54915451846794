import { Control, FieldErrors, useController } from "react-hook-form";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Select,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { VianikoEvent } from "../../../../types/events";
import { DollarsInput } from "../../../../components/forms/DollarsInput";
import { Organization } from "../../../../types/organization";
import { selectThemeProps } from "../../../../services/theme/overrides/select";
import { CURRENCIES } from "../../../../services/currencies";

interface PaymentConfigurationFormSectionProps {
  control: Control<VianikoEvent>;
  errors?: FieldErrors<VianikoEvent>;
  organization?: Organization;
}

export const PaymentConfigurationFormSection: React.FC<
  PaymentConfigurationFormSectionProps
> = ({ control, errors, organization }) => {
  const {
    field: {
      onChange: paymentTypeOnChange,
      onBlur: paymentTypeOnBlur,
      value: paymentTypeValue,
      name: paymentTypeName,
      ref: paymentTypeRef,
    },
  } = useController({
    name: "payment_type",
    defaultValue: "free",
    control,
    rules: { required: "Payment type is required" },
  });

  const {
    field: {
      onChange: currencyCodeOnChange,
      value: currencyCodeValue,
      name: currencyCodeName,
      ref: currencyCodeRef,
    },
  } = useController({
    name: "currency_iso_code",
    defaultValue: "USD",
    control,
  });

  return (
    <>
      <FormControl isInvalid={!!errors?.payment_type} width="100%">
        <FormLabel htmlFor={"payment_type"}>Payment type</FormLabel>
        <HStack>
          <Tooltip
            label={
              !organization
                ? "Create a feather in order to offer paid events"
                : organization.stripe_account_id === null
                ? "Setup your payment account in order to offer paid events"
                : ""
            }
          >
            <Select
              placeholder="Payment type"
              isInvalid={!!errors?.payment_type}
              isDisabled={
                !organization || organization.stripe_account_id === null
              }
              name={paymentTypeName}
              ref={paymentTypeRef}
              onChange={paymentTypeOnChange}
              onBlur={paymentTypeOnBlur}
              value={paymentTypeValue}
              {...selectThemeProps}
            >
              <option value="free">Free</option>
              <option value="fixed">Ticketed</option>
              <option value="slider">Sliding scale</option>
            </Select>
          </Tooltip>
          {errors?.payment_type && (
            <FormErrorMessage>{errors?.payment_type.message}</FormErrorMessage>
          )}

          {paymentTypeValue === "fixed" && (
            <Select
              variant="subtle"
              isInvalid={!!errors?.currency_iso_code}
              width="fit-content"
              name={currencyCodeName}
              ref={currencyCodeRef}
              onChange={currencyCodeOnChange}
              value={currencyCodeValue}
            >
              {CURRENCIES.map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.code}
                </option>
              ))}
            </Select>
          )}
        </HStack>
      </FormControl>

      {paymentTypeValue === "slider" ? (
        <VStack gap={4} marginTop={4}>
          <DollarsInput
            name="min_price_in_cents"
            label="Minimum price"
            defaultValue={1000}
            control={control}
          />
          <DollarsInput
            name="max_price_in_cents"
            label="Maximum price"
            defaultValue={2000}
            control={control}
          />
          <DollarsInput
            name="price_in_cents"
            label="Default price"
            defaultValue={1500}
            control={control}
          />
        </VStack>
      ) : null}
    </>
  );
};
