import {
  Box,
  Button,
  Heading,
  Icon,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StyledInput } from "../../components/forms/StyledInput";
import { VianikoUser } from "../../types/users";
import * as api from "../../services/api";
import { useVianikoUser } from "../../hooks/useVianikoUser";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { Panel } from "../../components/Panel";
import { MdAlternateEmail } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import {
  homeUrl,
  userEditUrl,
  userShowUrl,
} from "../../services/routes/urlBuilder";
import { PhotoUploadInput } from "../../components/forms/PhotoUploadInput";
import { TEXT_SECONDARY_COLOR } from "../../services/theme/colors";

const LINKED_IN_URL_DEFAULT = "linkedin.com/in/";

export const UserEditPage: React.FC = () => {
  const { id } = useParams();
  const user = useVianikoUser(id);
  const { currentUser, refetch: refetchCurrentUser } = useCurrentUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<VianikoUser>({ mode: "onBlur", values: user });

  const onSubmit: SubmitHandler<VianikoUser> = async (data) => {
    await api.users.updateUser(data);
    await refetchCurrentUser();
    const redirectTo =
      searchParams.get("redirectAfter") || (id ? userShowUrl(id) : homeUrl());
    navigate(redirectTo);
  };

  if (user && currentUser && user.id !== currentUser.id) {
    navigate(userEditUrl(currentUser.id));
  }

  if (!user) return null;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel>
          <Heading as="h1" size="xl" marginBottom={10}>
            Personal information
          </Heading>

          <Input type="hidden" {...register("id")} value={id} />
          <VStack gap={4}>
            <StyledInput
              name="first_name"
              label="First name"
              register={register}
              error={errors.first_name}
              options={{
                required: "This field is required",
                maxLength: {
                  value: 100,
                  message: "Max length of 100 characters",
                },
              }}
            />
            <StyledInput
              name="last_name"
              label="Last name"
              register={register}
              error={errors.last_name}
              options={{
                required: "This field is required",
                maxLength: {
                  value: 100,
                  message: "Max length of 100 characters",
                },
              }}
            />
            <Box width="100%">
              <StyledInput
                name="email"
                label="Email"
                type="email"
                register={register}
                error={errors.email}
                options={{
                  required: "This field is required",
                  maxLength: {
                    value: 100,
                    message: "Max length of 100 characters",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Enter a valid email",
                  },
                }}
              />
              <Text
                size="sm"
                color={TEXT_SECONDARY_COLOR}
                marginX={2}
                marginTop={2}
              >
                Emails are not displayed on your profile. We will only email
                tickets and updates from feather and event hosts
              </Text>
            </Box>
          </VStack>
        </Panel>

        <Panel>
          <VStack gap={4}>
            <StyledInput
              name="description"
              label="About yourself"
              register={register}
              error={errors.description}
              options={{
                maxLength: {
                  value: 100,
                  message: "Max length of 100 characters",
                },
              }}
              inputProps={{
                placeholder: "A few highlights ",
              }}
            />

            <StyledInput
              name="instagram_handle"
              register={register}
              error={errors.instagram_handle}
              options={{
                maxLength: {
                  value: 30,
                  message: "Max length of 30 characters",
                },
              }}
              inputProps={{ placeholder: "Instagram account" }}
              label="Instagram account"
              leftElement={<Icon as={MdAlternateEmail} />}
            />

            <StyledInput
              name="twitter_handle"
              register={register}
              error={errors.twitter_handle}
              options={{
                minLength: {
                  value: 4,
                  message: "Min length of 4 characters",
                },
                maxLength: {
                  value: 15,
                  message: "Max length of 15 characters",
                },
              }}
              inputProps={{ placeholder: "X (twitter) account" }}
              label="X (twitter) account"
              leftElement={<Icon as={MdAlternateEmail} />}
            />

            <StyledInput
              name="linkedin_url"
              register={register}
              error={errors.linkedin_url}
              inputProps={{ placeholder: LINKED_IN_URL_DEFAULT }}
              options={{
                pattern: {
                  value: /^linkedin\.com\/in\/[a-zA-Z0-9]+/,
                  message:
                    "url must be of the format linkedin.com/in/your-profile",
                },
              }}
              label="Linkedin account"
              leftElement={<Icon as={BiWorld} />}
            />
          </VStack>
        </Panel>

        <Panel>
          <PhotoUploadInput name="photo_id" control={control} type="single" />
        </Panel>

        <Button
          type="submit"
          variant="primary"
          size="lg"
          width="100%"
          marginTop={6}
          marginBottom={12}
        >
          Submit
        </Button>
      </form>
    </>
  );
};
