import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { authUrl } from "../services/routes/urlBuilder";

export const AuthenticatedRoutes: React.FC = () => {
  const { user, refreshing, initialized } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (initialized && !user && !refreshing) {
    navigate(authUrl(pathname));
  }

  return <Outlet />;
};
