import axiosInstance from "./axiosInstance";

export const getAccount = async (organizationId: string) => {
  const result = await axiosInstance.get(`/payments/${organizationId}/account`);
  return result.data;
};

export const createAccount = async (organizationId: string) => {
  const result = await axiosInstance.post("/payments/account", {
    organization_id: organizationId,
  });
  return result.data;
};

export const accountSession = async (organizationId: string) => {
  const result = await axiosInstance.post("/payments/account-session", {
    organization_id: organizationId,
  });
  return result.data;
};

interface SliderIntentOptions {
  amountInCents: number;
}

export const createPaymentIntentSlider = async (
  eventId: string,
  options?: SliderIntentOptions
) => {
  const { amountInCents } = options || {};
  const result = await axiosInstance.post(
    `/payments/events/${eventId}/create-payment-intent-slider`,
    {
      amountInCents,
    }
  );
  return result.data;
};

interface TicketedIntentOption {
  ticket_type_id: string;
  quantity: number;
}

export const createPaymentIntentTicketed = async (
  eventId: string,
  options: TicketedIntentOption[]
) => {
  const result = await axiosInstance.post(
    `/payments/events/${eventId}/create-payment-intent-ticketed`,
    options
  );
  return result.data;
};

export const fetchUnsignedWaivers = async (organizationId: string) => {
  const result = await axiosInstance.get(
    `/organizations/${organizationId}/waivers/unsigned`
  );
  return result.data;
};
