import { isPast } from "date-fns";
import { VianikoEvent } from "../../../types/events";
import { Button, Heading, HStack, Icon } from "@chakra-ui/react";
import { BiShareAlt } from "react-icons/bi";
import { PricingForm } from "./pricing";
import { useCurrentEventUser } from "../../../providers/CurrentEventUserProvider";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useNavigate } from "react-router-dom";
import {
  authUrl,
  eventShowUrl,
  paymentsRedirectorUrl,
} from "../../../services/routes/urlBuilder";
import { eventLevelTicketsUnvailable } from "../services";

interface EventActionButtonProps {
  event: VianikoEvent;
  onInviteFriendsClick: () => void;
  onGetTicketsClick: () => void;
}

export const EventActionButton: React.FC<EventActionButtonProps> = ({
  event,
  onInviteFriendsClick,
  onGetTicketsClick,
}) => {
  const { currentEventUser } = useCurrentEventUser();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const handleGetTickets = () => {
    if (!currentUser) {
      navigate(
        authUrl(eventShowUrl(event.id, { ticket_selection: true }), {
          eventId: event.id,
        })
      );
    } else if (!!event.parent_event_id) {
      navigate(eventShowUrl(event.parent_event_id, { ticket_selection: true }));
    }

    onGetTicketsClick();
  };

  const handleInviteFriends = () => {
    onInviteFriendsClick();
  };

  const handleJoinFree = () => {
    if (!currentUser) {
      navigate(authUrl(paymentsRedirectorUrl(event.id), { eventId: event.id }));
    } else {
      navigate(paymentsRedirectorUrl(event.id));
    }
  };

  return (
    <>
      {isPast(event.end_at) ? (
        <Button size="lg" variant="primary" isDisabled width="100%">
          This event is over
        </Button>
      ) : eventLevelTicketsUnvailable(event) ? (
        <Button size="lg" variant="primary" isDisabled width="100%">
          Sold out
        </Button>
      ) : currentEventUser?.status === "attending" ? (
        <HStack gap={1}>
          {event.payment_type === "fixed" && (
            <Button
              size="lg"
              variant="primary"
              isDisabled
              onClick={handleGetTickets}
            >
              You're going
            </Button>
          )}

          <Button
            size="lg"
            variant="primary"
            flexGrow={1}
            onClick={handleInviteFriends}
          >
            <Icon as={BiShareAlt} marginX={2} />
            <Heading as="h5" size="sm">
              Invite friends
            </Heading>
          </Button>
        </HStack>
      ) : event.payment_type === "fixed" || event.parent_event_id !== null ? (
        <>
          <Button
            onClick={handleGetTickets}
            variant="primary"
            size="lg"
            width="100%"
          >
            Get tickets
          </Button>
        </>
      ) : event.payment_type === "slider" ? (
        <PricingForm event={event} />
      ) : event.payment_type === "free" ? (
        <Button
          onClick={handleJoinFree}
          variant="primary"
          size="lg"
          width="100%"
        >
          Join for Free
        </Button>
      ) : null}
    </>
  );
};
