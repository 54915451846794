import { Box, Container } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { AppHeader } from "../components/AppHeader";
import { BACKGROUND_GRADIENT } from "../services/theme/colors";

const headerHeight = "50px";

export const Root: React.FC = () => {
  return (
    <Box height="100vh">
      <Box background={BACKGROUND_GRADIENT} height="auto" minHeight="100vh">
        <Box height={headerHeight} paddingX={5}>
          <AppHeader />
        </Box>
        <Box padding={1}>
          <Container maxW="container.sm">
            <Outlet />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
