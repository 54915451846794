import { VianikoEvent } from "../../types/events";
import axiosInstance from "./axiosInstance";

export const createEvent = async (data: VianikoEvent) => {
  const result = await axiosInstance.post("/events", data);
  return result.data;
};

export const updateEvent = async (id: string, data: VianikoEvent) => {
  const result = await axiosInstance.put(`/events/${id}`, data);
  return result.data;
};

export const fetchEvent = async (id: string) => {
  const result = await axiosInstance.get(`/public/events/${id}`);
  return result.data;
};

export const fetchMyEvents = async (
  direction: "past" | "future",
  afterTimestamp: string
) => {
  const result = await axiosInstance.get(
    `/events/my?direction=${direction}&afterTimestamp=${encodeURIComponent(
      afterTimestamp
    )}`
  );
  return result.data;
};

export const fetchMyCommunityEvents = async (
  startAt: string,
  endAt: string
) => {
  const result = await axiosInstance.get(
    `/events/my-community?startAt=${encodeURIComponent(
      startAt
    )}&endAt=${encodeURIComponent(endAt)}`
  );
  return result.data;
};

export const fetchEventAttendees = async (eventId: string) => {
  const result = await axiosInstance.get(`/events/${eventId}/attendees`);
  return result.data;
};

export const fetchEventMessages = async (eventId: string) => {
  const result = await axiosInstance.get(`/events/${eventId}/messages`);
  return result.data;
};

export const createEventMessage = async (eventId: string, content: string) => {
  const result = await axiosInstance.post(`/events/${eventId}/messages`, {
    content,
  });
  return result.data;
};

export const inviteUserToEvent = async (eventId: string, userId: string) => {
  const result = await axiosInstance.post(`/events/${eventId}/invite`, {
    user_id: userId,
  });
  return result.data;
};

export const deleteEvent = async (eventId: string) => {
  const result = await axiosInstance.delete(`/events/${eventId}`);
  return result.data;
};

export const fetchSubEvents = async (eventId: string) => {
  const result = await axiosInstance.get(
    `/public/events/${eventId}/sub_events`
  );
  return result.data;
};

export const fetchAllRelatedEvents = async (eventId: string) => {
  const result = await axiosInstance.get(
    `/public/events/${eventId}/all_related_events`
  );
  return result.data;
};
