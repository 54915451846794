import { ConfirmationResult } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link, Text } from "@chakra-ui/react";
import { PhoneNumber } from "./components/PhoneNumber";
import { OneTimePassword } from "./components/OneTimePassword";
import { homeUrl, userEditUrl } from "../../services/routes/urlBuilder";
import { Panel } from "../../components/Panel";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { FeaturedOrganizations } from "../organizations/FeaturedOrganizations";

export const PhoneAuth: React.FC = () => {
  const [confirmationResult, setConfirmationResult] =
    React.useState<ConfirmationResult>();
  let [searchParams] = useSearchParams();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const redirectParam = searchParams.get("redirect");
  const redirectTo = redirectParam
    ? decodeURIComponent(redirectParam)
    : homeUrl();
  const showFeatured =
    !searchParams.get("eventId") && !searchParams.get("organizationId");

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.full_name) {
        navigate(userEditUrl(currentUser.id, redirectTo));
        return;
      }

      navigate(redirectTo);
    }
  }, [currentUser, navigate, redirectTo]);

  const handleSubmitPhone = (result: ConfirmationResult) => {
    setConfirmationResult(result);
  };

  return (
    <>
      <Panel>
        <PhoneNumber
          onSubmit={handleSubmitPhone}
          confirmationResult={confirmationResult}
        />
        {confirmationResult && (
          <OneTimePassword confirmationResult={confirmationResult} />
        )}
        <Text variant="secondary" marginTop={10}>
          By signing up, you accept the{" "}
          <Link
            target="_blank"
            href="https://docs.google.com/document/d/1ymqN0VEMc6RSy0eCKkMC3QnysVQw83k44zBMM1z3Txg/edit?usp=sharing"
          >
            Privacy Policy
          </Link>
          ,{" "}
          <Link
            target="_blank"
            href="https://docs.google.com/document/d/1qStX57mnTta7hoy04v_9HSxBcWJMNEJ8V6UVknSchvY/edit?usp=sharing"
          >
            Terms of Service
          </Link>
          , and{" "}
          <Link
            target="_blank"
            href="https://docs.google.com/document/d/1WkhYQU12vSrZz0ga7HxUZCReAUyJUs_Yxl1czOxgeM0/edit?usp=sharing"
          >
            Payments Policy
          </Link>
        </Text>
      </Panel>
      {showFeatured && <FeaturedOrganizations />}
    </>
  );
};
