import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { VianikoEvent } from "../../../../types/events";
import { Panel } from "../../../../components/Panel";
import { useForm } from "react-hook-form";
import { QuantityInput } from "../../../../components/forms/QuantityInput";
import { TEXT_SECONDARY_COLOR } from "../../../../services/theme/colors";
import { priceInDollars } from "../../../../services/payments";
import { mobileModalThemeProps } from "../../../../services/theme/overrides/modal";
import { createPaymentIntentTicketed } from "../../../../services/api/payments";
import { useNavigate } from "react-router-dom";
import { eventPaymentUrl } from "../../../../services/routes/urlBuilder";
import { useState } from "react";

interface TicketTypeSelectionModalProps {
  event: VianikoEvent;
  isOpen: boolean;
  onClose: () => void;
}

interface TicketTypeSelection {
  [key: string]: number;
}

export const TicketTypeSelectionModal: React.FC<
  TicketTypeSelectionModalProps
> = ({ event, isOpen, onClose }) => {
  const [zeroTicketsError, setZeroTicketsError] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<TicketTypeSelection>({
    mode: "onBlur",
  });

  const modalContentProps = useBreakpointValue({
    base: mobileModalThemeProps,
    lg: {},
  });

  const onSubmit = async (data: TicketTypeSelection) => {
    setZeroTicketsError(false);

    const numTickets = Object.values(data).reduce(
      (acc, quantity) => acc + quantity,
      0
    );

    if (numTickets === 0) {
      setZeroTicketsError(true);
      return;
    }
    const { paymentToken, amountInCents } = await createPaymentIntentTicketed(
      event.id,
      Object.entries(data).map(([ticketTypeId, quantity]) => ({
        ticket_type_id: ticketTypeId,
        quantity,
      }))
    );

    navigate(eventPaymentUrl(event.id, { paymentToken, amountInCents }));
  };

  return (
    <Portal>
      <Modal
        scrollBehavior="inside"
        size="md"
        isOpen={isOpen}
        onClose={onClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent {...modalContentProps}>
            <ModalHeader>
              <HStack>
                <Heading size="lg" as="h2" margin={2}>
                  Select tickets
                </Heading>
                <ModalCloseButton margin={4} />
              </HStack>
            </ModalHeader>
            <ModalBody padding={1}>
              {event.ticket_types?.map((ticketType) => (
                <Panel key={ticketType.id}>
                  <HStack>
                    <Box>
                      <Heading size="sm" as="h4">
                        {ticketType.name}
                      </Heading>
                      <Text size="md" color={TEXT_SECONDARY_COLOR}>
                        {priceInDollars(
                          event.currency_iso_code,
                          ticketType.price_in_cents
                        )}
                      </Text>
                    </Box>
                    <Spacer />
                    {ticketType.capacity === null ||
                    (ticketType.num_tickets_available &&
                      ticketType.num_tickets_available > 0) ? (
                      <QuantityInput
                        name={ticketType.id}
                        max={
                          Math.min(
                            ...([
                              ticketType.num_tickets_available,
                              event.num_tickets_available,
                            ].filter((n) => !!n) as number[])
                          ) || 99
                        }
                        register={register}
                        error={errors[ticketType.id]}
                        prevValue={watch(ticketType.id)}
                        setValue={setValue}
                      />
                    ) : (
                      <Text size="md" color={TEXT_SECONDARY_COLOR}>
                        Sold out
                      </Text>
                    )}
                  </HStack>

                  {ticketType.description && (
                    <>
                      <Divider borderColor="gray.300" marginY={4} />

                      <Text size="sm" marginTop={4}>
                        {ticketType.description}
                      </Text>
                    </>
                  )}
                </Panel>
              ))}
              {zeroTicketsError && (
                <Text color="red.500" fontSize="sm" marginX={4}>
                  Please select at least one ticket
                </Text>
              )}
            </ModalBody>
            <ModalFooter>
              <Button type="submit" variant="primary" size="lg" width="100%">
                Check out
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Portal>
  );
};
