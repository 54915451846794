import { Heading } from "@chakra-ui/react";
import { Panel } from "../../components/Panel";
import { useFeaturedOrganizations } from "../../hooks/useFeaturedOrganizations";
import { OrganizationListThemeCard } from "./components/OrganizationListThemeCard";

export const FeaturedOrganizations = () => {
  const { organizations } = useFeaturedOrganizations();

  return organizations.length > 0 ? (
    <Panel>
      <Heading size="md" as="h3" marginBottom={4}>
        Featured feathers
      </Heading>

      {organizations.map((organization) => (
        <OrganizationListThemeCard
          key={organization.id}
          organization={organization}
        />
      ))}
    </Panel>
  ) : null;
};
