import { useCallback, useEffect, useState } from "react";
import { Ticket } from "../types/ticket";
import { fetchEventTickets } from "../services/api/eventUsers";

interface UseEventTickets {
  tickets: Ticket[];
  refetch: () => void;
}
export const useEventTickets = (eventId?: string): UseEventTickets => {
  const [tickets, setTickets] = useState([]);

  const fetch = useCallback(async () => {
    if (!eventId) return;
    const tickets = await fetchEventTickets(eventId);
    setTickets(tickets);
  }, [eventId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { tickets, refetch: fetch };
};
