import {
  LoaderFunction,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as api from "../../services/api";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { VianikoEvent } from "../../types/events";
import { EventOverview } from "./components/EventOverview";
import { EventAttendees } from "./components/EventAttendees";
import { EventAdminMessages } from "./components/EventAdminMessages";
import { useOrganizationUser } from "../../providers/CurrentOrganizationUserProvider";
import {
  eventShowUrl,
  homeUrl,
  organizationShowUrl,
} from "../../services/routes/urlBuilder";
import { useCurrentEventUser } from "../../providers/CurrentEventUserProvider";
import { EventThemeCard } from "./components/EventThemeCard";
import { Organization } from "../../types/organization";
import { ManageEventMenuListItems } from "./ManageEventMenuListItems";
import { useAttendees } from "./hooks/useAttendees";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components/PageHeader";
import { EventInviteFriendsModal } from "./components/EventInviteFriendsModal";
import { TicketTypeSelectionModal } from "./components/forms/TicketTypeSelectionModal";
import { EventTickets } from "./components/EventTickets";
import { EventActionButton } from "./components/EventActionButton";
import { useSubEvents } from "./hooks/useSubEvents";
import { ProgramsTab } from "./components/ProgramsTab";
import { useCurrentUser } from "../../providers/CurrentUserProvider";

interface LoaderData {
  event: VianikoEvent;
  organization: Organization;
}

export const loader: LoaderFunction = async ({
  params: { eventId },
}): Promise<LoaderData> => {
  if (!eventId) throw new Error("Event ID is required");

  const { event, organization } = await api.events.fetchEvent(eventId);
  return { event, organization };
};

export const EventShowPage: React.FC = () => {
  const { event, organization } = useLoaderData() as LoaderData;
  const { subEvents } = useSubEvents(event.id);
  const { currentUser } = useCurrentUser();
  const { organizationUser } = useOrganizationUser(event.organization_id);
  const { currentEventUser } = useCurrentEventUser();
  const { attendees, refetch: refetchAttendees } = useAttendees(event.id);
  const [isInviteFriendsModalOpen, setIsInviteFriendsModalOpen] =
    useState(false);
  const [isTicketTypeSelectionModalOpen, setIsTicketTypeSelectionModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [searchParams] = useSearchParams();
  const isInvite = !!searchParams.get("invite");
  const isTicketSelection = !!searchParams.get("ticket_selection");

  useEffect(() => {
    if (isInvite) {
      setIsInviteFriendsModalOpen(true);
    }
  }, [isInvite]);

  useEffect(() => {
    if (isTicketSelection && !!currentUser) {
      setIsTicketTypeSelectionModalOpen(true);
    }
  }, [isTicketSelection, currentUser]);

  const isAdmin = !!organizationUser?.is_owner || !!currentEventUser?.is_owner;
  const defaultIndex = path.match(/\/attendance/) ? 1 : 0;

  const handleDelete = async () => {
    navigate(
      event.organization_id
        ? organizationShowUrl(event.organization_id)
        : homeUrl()
    );
  };

  const handleRefundTickets = async () => {
    await refetchAttendees();
  };

  const handleCloseInviteModal = () => {
    window.location.href = eventShowUrl(event.id);
  };

  return (
    <>
      <EventInviteFriendsModal
        event={event}
        isOpen={isInviteFriendsModalOpen}
        onClose={handleCloseInviteModal}
      />
      <TicketTypeSelectionModal
        event={event}
        isOpen={isTicketTypeSelectionModalOpen}
        onClose={() => setIsTicketTypeSelectionModalOpen(false)}
      />

      <PageHeader
        menuListContent={
          isAdmin ? (
            <ManageEventMenuListItems
              eventId={event.id}
              recurringEventId={event.recurring_event_id}
              onDelete={handleDelete}
            />
          ) : null
        }
      />

      <Box marginBottom={1}>
        <EventThemeCard event={event} organization={organization} />
      </Box>

      <EventActionButton
        event={event}
        onGetTicketsClick={() => setIsTicketTypeSelectionModalOpen(true)}
        onInviteFriendsClick={() => setIsInviteFriendsModalOpen(true)}
      />

      <Tabs marginY={2} defaultIndex={defaultIndex}>
        <TabList>
          <Tab>Info</Tab>

          {(isAdmin || currentEventUser?.status === "attending") && (
            <Tab>{`${attendees?.length} ${
              attendees && attendees.length > 1 ? "attendees" : "attendee"
            }`}</Tab>
          )}
          {(isAdmin || subEvents.length > 0) && <Tab>Program</Tab>}

          {(isAdmin || currentEventUser?.status === "attending") &&
            event.payment_type === "fixed" && <Tab>Tickets</Tab>}

          {isAdmin && <Tab>Messages</Tab>}
        </TabList>

        <TabPanels>
          <TabPanel>
            <EventOverview event={event} />
          </TabPanel>

          {(isAdmin || currentEventUser?.status === "attending") && (
            <TabPanel>
              <EventAttendees
                event={event}
                attendees={attendees}
                onRefundTickets={handleRefundTickets}
              />
            </TabPanel>
          )}

          {(isAdmin || subEvents.length > 0) && (
            <TabPanel>
              <ProgramsTab event={event} subEvents={subEvents} />
            </TabPanel>
          )}

          {(isAdmin || currentEventUser?.status === "attending") &&
            event.payment_type === "fixed" && (
              <TabPanel>
                <EventTickets
                  event={event}
                  isAdmin={isAdmin}
                  onBuyTickets={() => setIsTicketTypeSelectionModalOpen(true)}
                />
              </TabPanel>
            )}

          {isAdmin && (
            <TabPanel>
              <EventAdminMessages />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};
