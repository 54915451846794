import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../providers/AuthProvider";
import { VianikoEvent } from "../../../../types/events";
import { SlidingPrice } from "./SlidingPrice";
import {
  authUrl,
  eventShowUrl,
  paymentsRedirectorUrl,
} from "../../../../services/routes/urlBuilder";
import { priceInDollars } from "../../../../services/payments";
import { createPaymentIntentSlider } from "../../../../services/api/payments";

interface PricingFormProps {
  event: VianikoEvent;
}

export const PricingForm: React.FC<PricingFormProps> = ({ event }) => {
  const { user } = useAuth();
  const [price, setPrice] = React.useState<number>(event.price_in_cents || 0);
  const navigate = useNavigate();

  const handleChangePrice = (price: number) => {
    setPrice(price);
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user) {
      navigate(authUrl(eventShowUrl(event.id, { ticket_selection: true })));
    }

    const { amountInCents, paymentToken } = await createPaymentIntentSlider(
      event.id,
      { amountInCents: price }
    );

    navigate(paymentsRedirectorUrl(event.id, { amountInCents, paymentToken }));
  };

  if (!event || event.payment_type !== "slider") return null;

  return (
    <form onSubmit={submitForm}>
      <Flex>
        <Button type="submit" variant="primary" size="lg">
          Join for {priceInDollars(event.currency_iso_code, price, 0)}
        </Button>
        <SlidingPrice
          event={event}
          onChange={handleChangePrice}
          flexGrow={1}
          marginTop={3}
          marginLeft={1}
        />
      </Flex>
    </form>
  );
};
